<template>
<div id="side-bar">
    <ul class="flex">
        <li>
            <router-link class="flex" :to="{ name: 'home' }">
                <div class="admin-icons logo-small"></div>
            </router-link>
        </li>
        <li>
            <router-link class="flex" :to="{ name: 'profile' }">
                <div class="admin-icons profile-icon"></div>
                <span>PROFILE</span>
            </router-link>
        </li>
        <li>
            <router-link class="flex" :to="{ name: 'orders' }" :class="{ active: active === 'orders' }">
                <div class="admin-icons orders-icon"></div>
                <span>ORDERS</span>
            </router-link>
        </li>
        <li>
            <router-link class="flex" :to="{ name: 'appointments' }">
                <div class="admin-icons appointments-icon"></div>
                <span>appointments</span>
            </router-link>
        </li>
        <li>
            <router-link class="flex" :to="{ name: 'feedbacks' }">
                <div class="admin-icons feedbacks-icon"></div>
                <span>feedbacks</span>
            </router-link>
        </li>
        <li>
            <router-link class="flex" :to="{ name: 'quote' }">
                <div class="admin-icons quote-icon"></div>
                <span>quotes</span>
            </router-link>
        </li>
    </ul>

</div>
</template>

<script>
export default {
    props: ['active']
}
</script>

<style scoped>

#side-bar
{
    height: 100vh; width: 90px;
    position: fixed;
    top: 0; left: 0;
    background-color: #000;
    box-shadow: 3px 0px 9px rgb(0,0,0,0.2);
    padding: 25px 0;
}

.logo-small
{
    background-size: 374.2px 315.8px;
    width: 50px; height: 61.7px;
    background-position: -8.3px -8.3px;
    margin-bottom: 10px;
}

.profile-icon
{
    background-size: 449px 379px;
    width: 45px; height: 40px;
    background-position: -285px -319px;
}

.orders-icon
{
    background-size: 449px 379px;
    width: 64px; height: 64px;
    background-position: -174px -10px;
}

.appointments-icon
{
    background-size: 449px 379px;
    width: 50px; height: 50px;
    background-position: -10px -179px;
}

.feedbacks-icon
{
    background-size: 449px 379px;
    width: 55px; height: 50px;
    background-position: -85px -104px;
}

.quote-icon
{
    background-size: 449px 379px;
    width: 50px; height: 50px;
    background-position: -328px -220px;
    position: relative;
    left: 5px;
}

ul
{
    flex-direction: column;
    justify-content: center;
}

li
{
    width: 100%;
    overflow: hidden;
    margin-bottom: 30px;
}

a
{
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

a span
{
    margin-top: 2px;
    font-size: 10px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}

a:hover span, a.router-link-exact-active.router-link-active span, a.active span { color: #E20613; }

a:hover .profile-icon, a.router-link-exact-active.router-link-active .profile-icon
{
    background-size: 449px 379px;
    width: 45px; height: 40px;
    background-position: -220px -319px;
}

a:hover .orders-icon, a.router-link-exact-active.router-link-active .orders-icon, a.active .orders-icon
{
    background-size: 449px 379px;
    width: 64px; height: 64px;
    background-position: -90px -10px;
}

a:hover .appointments-icon, a.router-link-exact-active.router-link-active .appointments-icon
{
    background-size: 449px 379px;
    width: 50px; height: 50px;
    background-position: -160px -104px;
}

a:hover .feedbacks-icon, a.router-link-exact-active.router-link-active .feedbacks-icon
{
    background-size: 449px 379px;
    width: 55px; height: 50px;
    background-position: -10px -104px;
}

a:hover .quote-icon, a.router-link-exact-active.router-link-active .quote-icon
{
    background-size: 449px 379px;
    width: 50px; height: 50px;
    background-position: -328px -150px;
}

</style>