<template>
	<div class="feedback-row flex justify-btw align-center">
		<span class="name">
			{{ feedback.name }}
		</span>
		<span class="message">
			{{ feedback.message }}
		</span>
		<span class="date">
			{{ makeDate(feedback.givenOn) }}
		</span>
		<span class="displayed">
			<label class="switch">
				<input
					type="checkbox"
					:id="feedback.feedbackID"
					:data-id="feedback.feedbackID"
					v-model="feedback.isDisplayed"
					@click="handleChange"
				/>
				<span class="slider round"></span>
			</label>
		</span>
		<span class="delete">
			<div
				class="admin-icons delete-btn"
				:data-id="feedback.feedbackID"
				@click="handleDelete"
			></div>
		</span>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import { Feedbacks } from '../../../services/Service';

export default {
    props: {
        feedback: {
            type: Object,
            required: true
        }
    },
    computed: {
        makeDate() {
            return date => {
                date = typeof date === "string" ? new Date(date) : date;
                return date.toLocaleDateString('en-IN', { year: 'numeric', month: 'long', day: 'numeric' });
            }
        },
        ...mapGetters([ 'allFeedbacks', 'displayedFeedbacks'])
    },
    methods: {
        handleDelete(e) { this.$emit('delete', e.target.dataset.id ); },
        async handleChange(e) {
            const { dataset: { id }, checked } = e.target;
            var notification;
            if( checked )
            {
                if( this.displayedFeedbacks.length < 10 )
                {
                    const response = await Feedbacks.update({ feedbackID: id, isDisplayed: true });
                    if( response.status === 200 )
                    {
                        notification = { type: 'success', message: 'Feedback will be displayed.' };
                    }
                    else
                    {
                        notification = { type: 'error', message: 'Error Changing Feedback' };
                    }
                }
                else
                {
                    e.target.checked = false;
                    notification = { type: 'error', message: 'Only 10 Feedbacks can be displayed.' };
                }
            }
            else
            {
                if( 5 < this.displayedFeedbacks.length )
                {
                    const response = await Feedbacks.update({ feedbackID: id, isDisplayed: false });
                    if( response.status === 200 )
                    {
                            notification = { type: 'success', message: 'Feedback will not be displayed.' };
                    }
                    else
                    {
                        notification = { type: 'error', message: 'Error Changing Feedback' };
                    }
                }
                else
                {
                    e.target.checked = true;
                    notification = { type: 'error', message: 'Atleast 5 Feedbacks needs to be displayed.' };
                }
            }
            this.$store.dispatch('addNotification', notification);
        }
    },
};
</script>

<style scoped>

.feedback-row
{
    min-height: 60px;
    padding: 9px 15px;
    border-bottom: 2px solid #000;
    cursor: pointer;
    min-width: 1370px;
}

.feedback-row span
{
    padding-right: 25px;
    font-weight: 600;
    font-size: 14.5px;
    line-height: 1.5;
    color: black;
}

.feedback-row span.name,
.feedback-row span.displayed,
.feedback-row span.date,
.feedback-row span.delete
{ width: 10%; }

.feedback-row span.message { width: 55%; }


.delete-btn
{
    background-size: 359.2px 303.2px;
    width: 40px; height: 40px;
    background-position: -120px -143.2px;
    cursor: pointer;
}

.delete-btn:hover
{
    background-size: 359.2px 303.2px;
    width: 40px; height: 40px;
    background-position: -206.4px -8px;
}

/* Slider */

.switch
{
    position: relative;
    display: inline-block;
    width: 50px;
    height: 22px;
    margin-top: 6px;
}

.switch input
{
    opacity: 0;
    width: 0;
    height: 0;
}

.slider
{
    position: absolute;
    cursor: pointer;
    top: 0; left: 0;
    right: 0; bottom: 0;
    background-color: #E20613;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before
{
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider { background-color: #00917c; }

input:focus + .slider { box-shadow: 0 0 1px #00917c; }

input:checked + .slider:before
{
    -webkit-transform: translateX(28px);
    -ms-transform: translateX(28px);
    transform: translateX(28px);
}

.slider.round { border-radius: 34px; }

.slider.round:before { border-radius: 50%; }

/* Slider end */

</style>
