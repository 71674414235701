<template>
<div>
    <SideBar />
    <FeedbackContainer />
</div>
</template>

<script>
import SideBar from '@/components/admin/SideBar.vue';
import FeedbackContainer from '@/components/admin/Feedbacks/FeedbackContainer.vue';
import { Feedbacks } from '../../services/Service';

export default {
    components: {
        SideBar,
        FeedbackContainer
    },
    methods: {
        async fetchAllFeedbacks() {
            const response = await Feedbacks.getAll();
            this.$store.dispatch('storeFeedbacks', response.data.result);
        }
    },
    created() {
        this.fetchAllFeedbacks();
    }
}
</script>