<template>
<div class="fb-container">
    <div class="fb-top-pane flex align-center">
        <h2>Feedbacks</h2>
    </div>

    <FeedbackTable :feedbacks="allFeedbacks" />

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import FeedbackTable from '@/components/admin/Feedbacks/FeedbackTable.vue';

export default {
    components: {
        FeedbackTable,
    },
    computed: {
        ...mapGetters([ 'allFeedbacks' ])
    }
}
</script>

<style scoped>

.fb-container
{
    position: absolute;
    top: 0; left: 90px;
    height: 100vh;
    width: calc(100% - 90px);
    background-color: #fff;
}

.fb-top-pane
{
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.1);
    position: relative;
    z-index: 3;
}

h2
{
    font-weight: 800;
    font-size: 26px;
}

</style>