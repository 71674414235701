<template>
<div>

    <div class="feedback-table" v-if=" !$store.state.feedbacksStored ">

        <div class="feedback-header-row feedback-row flex justify-btw align-center">
            <span class="name">
                Name
                <div class="sort-icon" data-name="name" @click="sort"></div>
            </span>
            <span class="message">
                Message
                <div class="sort-icon" data-name="message" @click="sort"></div>
            </span>
            <span class="date">
                Given on
                <div class="sort-icon" data-name="givenOn" @click="sort"></div>
            </span>
            <span class="displayed">
                displayed
                <div class="sort-icon" data-name="isDisplayed" @click="sort"></div>
            </span>
            <span class="delete">
                Delete
            </span>
        </div>

        <div class="feedback-row feedback-row flex justify-btw align-center">
            <span class="name"> <i class="pseudo-loader"></i> </span>
            <span class="message"> <i class="pseudo-loader"></i> </span>
            <span class="date"> <i class="pseudo-loader"></i> </span>
            <span class="displayed"> <i class="pseudo-loader"></i> </span>
            <span class="delete"> <i class="pseudo-loader"></i> </span>
        </div>

        <div class="feedback-row feedback-row flex justify-btw align-center">
            <span class="name"> <i class="pseudo-loader"></i> </span>
            <span class="message"> <i class="pseudo-loader"></i> </span>
            <span class="date"> <i class="pseudo-loader"></i> </span>
            <span class="displayed"> <i class="pseudo-loader"></i> </span>
            <span class="delete"> <i class="pseudo-loader"></i> </span>
        </div>

        <div class="feedback-row feedback-row flex justify-btw align-center">
            <span class="name"> <i class="pseudo-loader"></i> </span>
            <span class="message"> <i class="pseudo-loader"></i> </span>
            <span class="date"> <i class="pseudo-loader"></i> </span>
            <span class="displayed"> <i class="pseudo-loader"></i> </span>
            <span class="delete"> <i class="pseudo-loader"></i> </span>
        </div>

        <div class="feedback-row feedback-row flex justify-btw align-center">
            <span class="name"> <i class="pseudo-loader"></i> </span>
            <span class="message"> <i class="pseudo-loader"></i> </span>
            <span class="date"> <i class="pseudo-loader"></i> </span>
            <span class="displayed"> <i class="pseudo-loader"></i> </span>
            <span class="delete"> <i class="pseudo-loader"></i> </span>
        </div>

    </div>

    <div class="feedback-table" v-if=" $store.state.feedbacksStored " >

        <div class="feedback-header-row feedback-row flex justify-btw align-center">
            <span class="name">
                Name
                <div class="sort-icon" data-name="name" @click="sort"></div>
            </span>
            <span class="message">
                Message
                <div class="sort-icon" data-name="message" @click="sort"></div>
            </span>
            <span class="date">
                Given on
                <div class="sort-icon" data-name="givenOn" @click="sort"></div>
            </span>
            <span class="displayed">
                displayed
                <div class="sort-icon" data-name="isDisplayed" @click="sort"></div>
            </span>
            <span class="delete">
                Delete
            </span>
        </div>

        <FeedbackRow
            v-for="(feedback, index) in feedbacks"
            :key="index"
            :feedback="feedback"
            @delete="openDeleteModal"
        />

    </div>

    <div class="feedbacks-menu-row flex align-center">

        <div class="pagination flex justify-btw align-center" v-if="1 < noOfPages" >

            <div class="icon-sprite prev-page-icon"
                :class="{ 'hide': pageNo === 1 }"
                @click="pageNo--" ></div>

            <p class="page-show">
                Page {{ pageNo }} of {{ noOfPages }}
            </p>

            <div class="icon-sprite next-page-icon"
                :class="{ 'hide': pageNo === noOfPages }"
                @click="pageNo++" ></div>
        </div>
    </div>

    <div class="overlay d-none" @click="closeOverlay">
        <div class="modal delete-modal d-none" @click="handleModalClick">
            <span @click="closeOverlay"></span>
            <p>Are you sure, Do you want to delete this feedback?</p>

            <div class="btns-box flex">
                <button class="btn fancy-btn confirm-btn delete-confirm-btn" @click="deleteFeedback">YES</button>
                <button class="btn fancy-btn cancel-btn" @click="closeOverlay">NO</button>
            </div>
        </div>
    </div>

</div>
</template>

<script>
import $ from 'jquery';
import FeedbackRow from './FeedbackRow';
import { Feedbacks } from '../../../services/Service';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            pageNo: 1,
            id: null
        }
    },
    components: {
        FeedbackRow,
    },
    props: {
        feedbacks: {
            type: Array,
            required: true
        }
    },
    methods: {
        openDeleteModal( id ) {
            $('.overlay').removeClass('d-none');
            $('.delete-modal').removeClass('d-none');
            this.id = id;
        },
        handleModalClick(e) { e.stopPropagation(); },
        closeOverlay() {
            $('.overlay').addClass('d-none');
            $('.modal').addClass('d-none');
        },
        sort(e) {

            var { name } = e.target.dataset;
            var ascorder = $(e.target).hasClass('asc-feedback');
            this.feedbacks.sort( (a,b) =>
            {
                a = typeof a[name] === 'string' ? a[name].toLowerCase() : a[name];
                b = typeof b[name] === 'string' ? b[name].toLowerCase() : b[name];
                if( ascorder )
                {
                    if( a < b ) { return 1; }
                    if( a > b ) { return -1; }
                }
                else
                {
                    if( a < b ) { return -1; }
                    if( a > b ) { return 1; }
                }
                return 0;
            });
            $('.asc-feedback').removeClass('asc-feedback');
            if( ascorder ) { $(e.target).removeClass('asc-feedback'); }
            else { $(e.target).addClass('asc-feedback'); }
        },
        async deleteFeedback() {
            $('.delete-confirm-btn').blur();
            $('.delete-confirm-btn').html(`<div class="spinner-border" role="status"></div>`);
            const resp = await Feedbacks.delete( { feedbackID: this.id } );

            if( resp.status === 200 )
            {
                const index = this.$store.state.feedbacks.findIndex( fb => fb.feedbackID === this.id );
                this.$store.state.feedbacks.splice( index, 1 );

                const notification = {
                    type: 'success',
                    message: 'Feedback deleted successfully.'
                };
                this.$store.dispatch('addNotification', notification);
            }
            this.closeOverlay();
            $('.delete-confirm-btn').html(`Confirm`);
        }
    },
    computed: {
        noOfPages() {
            return Math.ceil(this.feedbacks.length / 20)
        },
        pagefeedbacks() {
            var start = (this.pageNo - 1) * 20;
            return this.feedbacks.slice(start, start + 20);
        },
        ...mapGetters(['allFeedbacks'])
    }
}
</script>

<style scoped>

.feedbacks-menu-row
{
    height: 80px;
    border-bottom: 2px solid #f1f1f1;
    padding: 0 25px;
    box-shadow: 1px 1px 4px rgb(0, 0, 0, 0.1);
    background-color: #fff;
}

.feedback-table
{
    margin: 30px auto;
    border: 3px solid #000;
    height: calc(100vh - 220px);
    overflow-y: auto;
    overflow-x: auto;
    width: calc(100% - 50px);
    background-color: #fff;
}

.feedback-row
{
    min-height: 60px;
    padding: 9px 15px;
    border-bottom: 2px solid #000;
    cursor: pointer;
    min-width: 1370px;
}

.feedback-header-row.feedback-row
{
    min-height: auto;
    height: 40px;
    border: 0;
    padding: 0 15px;
    text-transform: capitalize;
    position: -webkit-sticky;
    position: sticky;
    top: 0; left: 0;
    background-color: grey;
    z-index: 2;
}

.feedback-row span
{
    padding-right: 25px;
    font-weight: 600;
    font-size: 14.5px;
    line-height: 1.5;
    color: black;
}

.feedback-header-row.feedback-row span
{
    font-weight: 600;
    font-size: 12.5px;
    padding-right: 25px;
    color: #fff;
    cursor: pointer;
    position: relative;
}

.sort-icon
{
    position: absolute;
    top: 50%; right: 10px;
    transform: translateY(-50%);
    border-top: 6px solid white;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    display: none;
}

.feedback-header-row span:hover .sort-icon { display: block; }

.sort-icon.asc-feedback { transform: translateY(-50%) rotate(180deg); }

.feedback-row span.name,
.feedback-row span.displayed,
.feedback-row span.date,
.feedback-row span.delete
{ width: 10%; }

.feedback-row span.message { width: 55%; }

.page-show
{
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    margin: 0 30px;
}

.pagination { margin-left: auto; }

.icon-sprite.prev-page-icon, .icon-sprite.next-page-icon
{
    background-size: 421.2px 294.1px;
    background-position: -382.4px -32px;
	height: 30px; width: 30px;
    cursor: pointer;
    filter: drop-shadow(0px 0px 2px rgb(0,0,0,0.1));
    transition: transform 0.2s ease;
}

.icon-sprite.next-page-icon{ transform: rotate(180deg); }

.prev-page-icon:hover { transform: scale3d(1.1,1.1,1.1); }

.next-page-icon:hover { transform: rotate(180deg) scale3d(1.1,1.1,1.1); }

.hide { visibility: hidden; }

/* Overlay */

.overlay
{
    background-color: rgb(0,0,0,0.5);
    height: 100vh; width: 100vw;
    position: fixed;
    top: 0; left: 0;
    z-index: 10;
}

.modal
{
    position: fixed;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    width: 350px;
    border-radius: 12px;
    padding: 60px 25px 30px;
}

.modal span, .close-icon
{
    position: absolute;
    right: 10px; top: 10px;
    cursor: pointer;
    width: 30px; height: 30px;
}

.modal span::before, .close-icon::before, .modal span::after, .close-icon::after
{
    content: '';
    position: absolute;
    width: 3px; height: 30px;
    top: 0; left: 50%;
    background-color: black;
}

.modal span::before, .close-icon::before { transform: translateX(-50%) rotate(45deg); }

.modal span::after, .close-icon::after { transform: translateX(-50%) rotate(-45deg); }

.modal span:hover, .close-icon:hover { opacity: 0.5; }

.modal p
{
    font-size: 18px;
    line-height: 1.5;
    font-weight: 500;
    text-align: center;
}

.btns-box { margin-top: 25px; }

.confirm-btn, .cancel-btn
{
    height: 50px; width: 100px;
    border-radius: 3px;
    font-size: 16px;
    padding-top: 3px;
    box-shadow: 1px 1px 5px rgb(0, 0, 0, 0.3);
    color: #fff;
    font-weight: 700;
}

.confirm-btn { background-color: #00917c; margin-left: auto; margin-right: 15px; }

.cancel-btn { background-color: #E20613; margin-right: auto; }



.feedback-row span i { height: 20px; width: 75% !important; }

</style>